<template>
  <div class="page form" v-loading="loading">
    <h2>{{ id ? `编辑章节：《${form.title}》` : '新建章节'}}</h2>

    <section>
      <el-form class="form" ref="form" :rules="rules" :model="form" label-width="100px">
        <el-form-item label="课程">
          《{{ form.course.title}}》
          <router-link class="el-button el-button--mini" style="margin-left: 20px"
                       :to="'/course-editor?id=' + form.course.id">查看课程
          </router-link>
        </el-form-item>
        <el-form-item label="标题" prop="title" required>
          <el-input v-model="form.title" placeholder="【必选】请输入章节标题"/>
        </el-form-item>
        <el-form-item label="课程封面">
          <input-image-single v-model="form.cover"/>
          <div class="tip">推荐尺寸 宽700 x 高350</div>
        </el-form-item>
        <el-form-item label="添加视频">
          <video-comp v-model="form.video_id"/>
        </el-form-item>
        <el-form-item prop="content" label="内容">
          <text-editor class="editor" v-model="form.content"/>
        </el-form-item>
        <el-form-item label="课程附件">
          <uploader-files v-model="form.append_files" :action="$settings.urls.upload"
                          tip="附件大小最大 1GB，需要更大的附件请联系开发者"/>
        </el-form-item>
        <el-form-item label="推荐商品">
          <el-transfer class="ad-select"
                       filterable
                       :titles="['可用项', '已包含']"
                       :filter-method="filterMethod"
                       filter-placeholder="请输入关键词"
                       v-model="form.append_ads"
                       :data="available_objs">
          </el-transfer>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">提交</el-button>
          <el-button v-if="id" type="danger" @click="onDelete">删除</el-button>
        </el-form-item>
      </el-form>
    </section>

    <section v-if="id" style="width: 700px">
      <h3>打卡统计</h3>
      <el-table :data="check_list" style="width: 600px">
        <el-table-column label="用户" width="160px">
          <template slot-scope="scope">
                <xid-link :xid="'user-' + scope.row.user.id"
                          :name="scope.row.user.realname || scope.row.user.nickname"/>
              </template>
        </el-table-column>
        <el-table-column label="打卡章节">
          <template slot-scope="scope">
            {{ form.title }}
              </template>
        </el-table-column>
        <el-table-column prop="datetime_created" label="打卡时间" width="160px">
        </el-table-column>



      </el-table>
          <el-pagination class="pagination"
                   layout="total, prev, pager, next"
                   @current-change="() => loadCheckRecords(check_page)"
                   :current-page.sync="check_page"
                   :page-size="20"
                   :total="check_total"/>
    </section>


  </div>
</template>

<script>
import XidLink from "@/comps/xid-link";
export default {
  components: {XidLink},
  data() {
    return {
      id: this.$route.query.id,
      course_id: this.$route.query.course_id,
      loading: false,
      append_files: [],
      rules: {},
      form: {
        course: {}
      },
      available_objs: [],
      check_list: [],
      check_page: 0,
      check_total: 0,
    }
  },
  mounted() {
    if (this.course_id) {
      this.$api.request('/course/get_course', {id: this.course_id})
          .then(data => {
            this.form.course = data
          })
    }

    this.load()

  },
  methods: {
    async load() {
      if (this.id) {
        this.form = await this.$api.request('/course/get_chapter', {id: this.id, show_ads: false})
      }

      this.available_objs = await this.$api.request('/list_available_objects', {model: 'goods'})

this.loadCheckRecords(1)
    },

    loadCheckRecords(page) {
      this.$api.request('/user/query_xid_check_record',
          {xid: `course_chapter-${this.id}`, page: page})
          .then(d => {
            this.check_list = d.contains
            this.check_page = d.page
            this.check_total = d.total
          })
    },
    onSubmit() {
      const url = this.id ? '/course/update_chapter' : '/course/create_chapter'
      this.$api.request(url, {id: this.id, course_id: this.course_id, ...this.form})
          .then(data => {
            this.$message.success('操作成功!')
            this.$router.push({name: 'course-chapter-editor', query: {id: data.id}})
          })

    },
    onDelete() {
      this.$confirm('您确定要删除吗？').then(() => {
        this.$api.request('/course/remove_chapter', {id: this.id}).then(data => {
          this.$message.success('删除成功！')
          this.$router.push('/course-editor?id=' + this.form.course.id)
        })
      })
    },
    filterMethod(query, item) {
      return (item.label || '').toLowerCase().indexOf(query) > -1
    },
  }
}
</script>

<style lang="scss">

.ad-select {
  width: 800px !important;

  .el-transfer-panel {
    width: 300px !important;
  }
}

</style>

